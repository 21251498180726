import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: ",
        ";\n  height: 100%;\n  transition: width 200ms ease;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 4px !important;\n  transform: translateY(-100%);\n  position: absolute;\n  background-color: ",
        ";\n  box-shadow: ",
        ";\n  overflow: hidden;\n  top: 69px; //65 + 4\n  left: 0;\n  right: 0;\n\n  ",
        " {\n    border-top-left-radius: ",
        ";\n    border-bottom-left-radius: ",
        ";\n  }\n\n  ",
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { space, variant as StyledSystemVariant } from "styled-system";
import { lightColors } from "../../theme";
import { styleVariants, styleScales } from "./themes";
import { variants } from "./types";
export var Bar = styled.div.withConfig({
    componentId: "sc-39f1f5d4-0"
})(_templateObject(), function(param) {
    var theme = param.theme, $useDark = param.$useDark, primary = param.primary, $background = param.$background;
    if ($background) return $background;
    if ($useDark) return primary ? theme.colors.primary : "".concat(theme.colors.secondary80);
    return primary ? lightColors.secondary : "".concat(lightColors.secondary80);
});
Bar.defaultProps = {
    primary: false
};
var StyledProgress = styled.div.withConfig({
    componentId: "sc-39f1f5d4-1"
})(_templateObject1(), function(param) {
    var theme = param.theme, $useDark = param.$useDark;
    return $useDark ? theme.colors.input : lightColors.input;
}, function(param) {
    var theme = param.theme;
    return theme.shadows.inset;
}, Bar, function(param) {
    var variant = param.variant;
    return variant === variants.FLAT ? "0" : "32px";
}, function(param) {
    var variant = param.variant;
    return variant === variants.FLAT ? "0" : "32px";
}, StyledSystemVariant({
    variants: styleVariants
}), StyledSystemVariant({
    prop: "scale",
    variants: styleScales
}), space);
export default StyledProgress;
